import { IAuth, Loader } from "@fanatics-live/common-components";
import { t } from "i18next";
import {
  createBrowserRouter,
  Navigate,
  NavigateOptions,
  Params,
  RouteObject,
  RouterProvider,
} from "react-router-dom";

import { CheckoutError, Handle, Layout } from "@/components";

import { MONOLITH_URL } from "./env";

const routes = (): Array<RouteObject & { handle?: Handle }> => [
  {
    index: true,
    element: <Navigate to={"/invoices"} />,
  },
  {
    path: "invoices",
    async lazy() {
      const { Invoices } = await import("@/legacy/invoices");
      return { Component: Invoices };
    },
  },
  {
    path: "invoices/:invoiceId",
    async lazy() {
      const { Cart } = await import("@/components/Cart");
      return { Component: Cart };
    },
  },
  {
    path: "invoices/:invoiceId/details",
    async lazy() {
      const { Details } = await import(
        "@/components/checkoutV2/details/Details"
      );
      return { Component: Details };
    },
    handle: {
      breadcrumb: (params: Params) => [
        { href: MONOLITH_URL, label: "breadcrumb.home" },
        { href: "/invoices", label: "breadcrumb.orders" },
        {
          label: [
            "breadcrumb.order",
            { invoiceId: params.invoiceId as string },
          ],
        },
      ],
    },
  },
  {
    path: "cart/:listingId",
    async lazy() {
      const { Cart } = await import("@/components/Cart");
      return { Component: Cart };
    },
  },
  {
    path: "multicart/:cartId",
    async lazy() {
      const { Multicart } = await import(
        "@/components/checkoutV2/multicart/Multicart"
      );
      return { Component: Multicart };
    },
  },
  {
    path: "multicart/:cartId/details",
    async lazy() {
      const PaymentSummary = await import(
        "@/components/checkoutV2/PaymentSummary"
      );
      return { Component: PaymentSummary.default };
    },
    handle: {
      breadcrumb: (_params: Params, state: NavigateOptions["state"]) => {
        if (!state?.orderId) {
          return null;
        }
        return [
          { href: MONOLITH_URL, label: "breadcrumb.home" },
          { href: "/invoices", label: "breadcrumb.orders" },
          {
            label: ["breadcrumb.order", { invoiceId: state.orderId as string }],
          },
        ];
      },
    },
  },
  {
    path: "*",
    element: (
      <CheckoutError message={t("errors.page_not_found")} statusCode={404} />
    ),
  },
];

export default function AppRouter({
  authState,
  isInitializing,
  refetchProfile,
}: {
  authState: IAuth;
  isInitializing: boolean;
  refetchProfile: () => Promise<void>;
}) {
  const router = createBrowserRouter([
    {
      element: (
        <Layout
          authState={authState}
          isInitializing={isInitializing}
          refetchProfile={refetchProfile}
        />
      ),
      children: routes(),
    },
  ]);
  return <RouterProvider router={router} fallbackElement={<Loader />} />;
}
