import { ModalProvider as CCModalProvider } from "@fanatics-live/common-components";
import type { ComponentProps } from "react";

import { STRIPE_MARKETPLACE_PUBLISHABLE_KEY } from "@/env";
import { useFeature } from "@/hooks/useFeature";
import { FeatureFlags } from "@/utils/featureFlags";

type ModalProviderProps = ComponentProps<typeof CCModalProvider>;

export const ModalProvider = (props: ModalProviderProps) => {
  const { value: fetchPublishableKeyEnabled } = useFeature(
    FeatureFlags.WEB_ENABLE_FETCH_PUBLISHABLE_KEY,
  );

  return (
    <CCModalProvider
      {...props}
      enableFetchPublishableKey={fetchPublishableKeyEnabled}
      stripeMarketplaceKey={
        !fetchPublishableKeyEnabled
          ? STRIPE_MARKETPLACE_PUBLISHABLE_KEY ?? ""
          : undefined
      }
    />
  );
};
