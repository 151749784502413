import { useSplitTreatments } from "@splitsoftware/splitio-react";

import {
  FeatureFlags,
  FeatureValue,
  TreatmentParsersMap,
} from "@/utils/featureFlags";
import { ESplitError, onSplitError } from "@/utils/split";
import { analyticsTrack, ETrackingEvents, track } from "@/utils/tracking";

type UseFeatureReturn<TFeatureName extends FeatureFlags> = {
  isReady?: boolean;
  value?: FeatureValue<TFeatureName>;
  error?: Error | null | unknown;
};

export const useFeature = <const TFeatureName extends FeatureFlags>(
  featureName: TFeatureName,
): UseFeatureReturn<TFeatureName> => {
  const { isReady, treatments, hasTimedout } = useSplitTreatments({
    names: [featureName],
  });
  if (hasTimedout) {
    analyticsTrack(ETrackingEvents.SPLIT_TIMEOUT_ERROR, {
      error: "Split timed out",
    });
    const value = TreatmentParsersMap[featureName](
      treatments[featureName],
    ) as FeatureValue<TFeatureName>;
    return { isReady: true, value };
  }
  if (!isReady) {
    return { isReady: false, value: undefined };
  }

  try {
    const value = TreatmentParsersMap[featureName](
      treatments[featureName],
    ) as FeatureValue<TFeatureName>;
    return { isReady, value };
  } catch (error) {
    track(ETrackingEvents.SPLIT_SCHEMA_ERROR, {
      error: "Error parsing split treatment",
      data: { error, featureName },
    });
    return {
      error: onSplitError(ESplitError.Schema, error),
      isReady: true,
      value: undefined,
    };
  }
};
