import { useQuery } from "@apollo/client";
import {
  IAuth,
  Navigation,
  useIsMobile,
  FanaticsCollectLogoFull,
  Loader,
} from "@fanatics-live/common-components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Outlet, useLocation } from "react-router-dom";

import { Breadcrumb } from "@/components";
import {
  MONOLITH_URL,
  PAST_SEARCH_URL,
  FAN_ID_BASE_URL,
  FAN_ID_CLIENT_ID,
  FAN_ID_PREVIEW,
  SEARCH_URL,
  COLLECT_CART_URL,
} from "@/env";
import { collectCurrentUser } from "@/gql";
import { useFeature } from "@/hooks/useFeature";
import { cn } from "@/utils";
import { FeatureFlags } from "@/utils/featureFlags";
import { identify } from "@/utils/tracking";

import "@/globals.css";

interface INavigationProvider {
  authState: IAuth;
  isInitializing: boolean;
  refetchProfile: () => void;
}

export const Layout = ({ authState, isInitializing }: INavigationProvider) => {
  const { value: notificationsEnabled } = useFeature(
    FeatureFlags.WEB_ENABLE_OFFER_NOTIFICATION_BADGES,
  );
  const { value: plaidPaApprovalEnabled } = useFeature(
    FeatureFlags.WEB_ENABLE_PLAID_PA_APPROVAL,
  );

  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isMobile = useIsMobile();
  const { data, loading } = useQuery(collectCurrentUser);
  const [identifyInitialized, setIndentifyInitialized] = useState(false);

  if (!loading && data && !identifyInitialized) {
    const { tenantId, email } = data?.collectCurrentUserV2 ?? {};
    identify(tenantId, { email: email?.address ?? "" });
    setIndentifyInitialized(true);
  }

  const isPaymentPage =
    pathname.split("/").filter((s) => s !== "").length === 2 &&
    !pathname.includes("order");

  const isMulticartPage = pathname.includes("/multicart");

  return isPaymentPage ? (
    <div
      className={cn(
        "min-h-screen bg-neutral-0",
        isMulticartPage && "bg-neutral-50",
      )}
    >
      <div>
        <div
          className={cn(
            "container max-w-[1144px] md:px-4",
            isMulticartPage && "max-w-[1440px]",
          )}
        >
          <div className="flex items-center justify-between border-b border-neutral-200 pb-[17px] pl-2 pr-2 pt-4 md:border-none md:pl-0 md:pt-[28px]">
            <FanaticsCollectLogoFull
              width={isMobile ? "100px" : "166px"}
              height={isMobile ? "24px" : "40px"}
            />
            <h1 className="text-center text-headline-xl md:text-headline-3xl">
              {t("common.header_checkout")}
            </h1>
            {isMulticartPage ? (
              <Link
                className="w-[100px] text-right text-body-sm-bold underline md:w-[166px]"
                to={COLLECT_CART_URL}
              >
                {t("common.back_to_cart")}
              </Link>
            ) : (
              <div className="w-[100px] md:w-[166px]" />
            )}
          </div>
        </div>

        <Outlet />
      </div>
    </div>
  ) : (
    <Navigation
      auth={authState}
      fanaticsCollectBaseUrl={MONOLITH_URL}
      fanIdBaseUrl={FAN_ID_BASE_URL ?? ""}
      fanIdClientId={FAN_ID_CLIENT_ID ?? ""}
      fanIdPreview={FAN_ID_PREVIEW}
      searchBaseUrl={SEARCH_URL}
      pastSearchBaseUrl={PAST_SEARCH_URL}
      showNonProdBanner={true}
      isInitializing={isInitializing}
      enableOfferNotificationBadges={notificationsEnabled}
      enablePlaidPAApproval={plaidPaApprovalEnabled}
    >
      <Breadcrumb />
      {isInitializing ? <Loader /> : <Outlet />}
    </Navigation>
  );
};
