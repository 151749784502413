import { GRAPHQL_API_URL } from "@/env";

export const refreshToken = async (refreshToken: string) => {
  try {
    // We do it with the fetch API so that apollo-client doesn't reference itself and also that we do not create a new instance of apollo-client
    const response = await fetch(GRAPHQL_API_URL!, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        query: `
                  mutation webCheckoutRefreshTokenMutation {
                      collectRefreshToken(refreshToken: "${refreshToken}") {
                          accessToken
                          refreshToken
                      }
                  }
              `,
      }),
    });
    return response.json();
  } catch (error) {
    throw new Error("token.refresh.failed");
  }
};
