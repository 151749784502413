import { PropsWithChildren, useEffect, useState } from "react";

import { useFeature } from "@/hooks/useFeature";
import { FeatureFlags } from "@/utils/featureFlags";

import { initialize } from "./initialize";

export const DatadogProvider = ({ children }: PropsWithChildren) => {
  const [isInitialized, setIsInitialized] = useState(false);

  const {
    value: logsSessionSampleRate,
    isReady,
    error,
  } = useFeature(FeatureFlags.DATADOG_BROWSER_LOGS_SESSION_SAMPLE_RATE);

  useEffect(() => {
    if (isReady && !isInitialized && !error) {
      initialize(logsSessionSampleRate || 0, 0, 0);

      setIsInitialized(true);
    }
  }, [isInitialized, isReady, logsSessionSampleRate, error]);

  return children;
};
